.at-stopwatchcontent {
	width: 100%;
	float: left;
	position: relative;
	background: #fff;
	height: calc(100% - 48px);
}

.at-calculatorholder {
	top: 10%;
	left: 40%;
	min-width: 300px;
	width: 525px;
	float: left;
	z-index: 11;
	display: none;
	background: #fff;
	border-radius: 3px;
	position: absolute;
	border: 1px solid #02a2de;
	-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.at-opancalculator {
	width: 100%;
	float: left;
	height: 100%;
	display: block;
	position: relative;
}

.at-resultscreen {
	width: 100%;
	float: left;
	padding: 23px 30px;
	background: #02a2de;
}

.at-calculation {
	border: 0;
	margin: 0;
	padding: 0;
	color: #fff;
	width: 100%;
	float: right;
	font-size: 32px;
	margin: 0 0 5px;
	background: none;
	box-shadow: none;
	font-weight: 300;
	text-align: right;
	line-height: 32px;
	font-family: Poppins, Serif;
}

.at-calculation:focus {
	border: 0;
	outline: none;
}

.at-resultarea {
	width: 100%;
	float: left;
	color: #fff;
	font-size: 50px;
	font-weight: 300;
	text-align: right;
	line-height: 43px;
	font-family: Poppins, Serif;
}

.at-keysholder {
	width: 100%;
	float: left;
	position: relative;
	height: calc(100% - 96px);
}

.at-scientifickeys {
	top: 0;
	left: 0;
	width: 40%;
	opacity: 0;
	float: left;
	height: 100%;
	overflow: hidden;
	position: absolute;
	visibility: hidden;
	background: #f5fbfe;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-opensceintificcalculator {
	opacity: 1;
	position: static;
	visibility: visible;
}

.at-simplekeys {
	width: 100%;
	float: right;
	background: #fff;
	position: relative;
	height: 100%;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-simplekeyswidth {
	width: 60%;
}

.at-keysholder button {
	border: 0;
	color: #000;
	padding: 5px;
	opacity: 0.70;
	height: 16.66%;
	cursor: pointer;
	font-size: 20px;
	box-shadow: none;
	background: none;
	line-height: 23px;
}

.at-keysholder button:focus {
	outline: none;
}

.at-scientifickeys button {
	float: left;
	width: 33.33%;
	border: 1px solid #e5f1f7;
}

.at-simplekeys button {
	width: 25%;
	float: left;
	border: 1px solid #e5f1f7;
}

.at-simplekeys .at-btnans {
	width: 50%;
}

.at-simplekeys .at-equalsign {
	width: 100%;
}

.at-simplekeys .at-equalsign, .at-simplekeys .at-btncusomestyle, .at-simplekeys .at-btncusomestyle {
	color: #020001;
	font-weight: 700;
	background: #e6e9eb;
}

.at-btnopensceintific {
	top: 50%;
	left: 100%;
	width: 180px;
	font-size: 14px;
	cursor: pointer;
	line-height: 40px;
	position: fixed;
	text-align: center;
	background: #02a2de;
	color: #fff !important;
	border-radius: 5px 5px 0 0;
	-webkit-transform: translateY(-100%) rotate(90deg);
	-moz-transform: translateY(-100%) rotate(90deg);
	-ms-transform: translateY(-100%) rotate(90deg);
	transform: translateY(-100%) rotate(90deg);
	-webkit-transform-origin: left bottom;
	-moz-transform-origin: left bottom;
	-ms-transform-origin: left bottom;
	transform-origin: left bottom;
}

.at-callactions {
	right: 0;
	margin: 0;
	bottom: 0;
	padding: 0;
	z-index: 9;
	position: absolute;
}

.at-stopwatchcontent {
	width: 100%;
	float: left;
	position: relative;
	background: #fff;
	height: calc(100% - 48px);
}