.at-stopwatchcontent {
	width: 100%;
	float: left;
	position: relative;
	background: #fff;
	height: calc(100% - 48px);
}

.at-calculatorholder {
	top: 10%;
	left: 40%;
	min-width: 300px;
	width: 525px;
	float: left;
	z-index: 11;
	display: none;
	background: #fff;
	border-radius: 3px;
	position: absolute;
	border: 1px solid #02a2de;
	-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.at-opancalculator {
	width: 100%;
	float: left;
	height: 100%;
	display: block;
	position: relative;
}

.modal__wrapper {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	justify-content: center;
	align-items: center;
}

.modal {
	width: 420px;
	height: 300px;
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0 2px 4px #000;
}

.modal--top {
	display: flex;
	height: 50%;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2345;
	color: #4b4b4b;
	font-size: 28px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background: url("https://image.ibb.co/h3nRnm/Screenshot_2017_11_19_welcome_screen_2x_png_PNG_Image_800_600_pixels.png");
}

.overlay:before {
	position: absolute;
	content: " ";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: -1;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: #82faff;
	opacity: 0.5;
}

.modal--bottom {
	display: flex;
	height: 50%;
	padding: 6%;
	color: #585a5b;
	flex-direction: column;
	justify-content: space-between;
}

.modal__btn {
	height: 45px;
	width: 110px;
	border: none;
	color: #005597;
	cursor: pointer;
	font-size: 16px;
	align-self: right;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	background: #aaf0ff;
}

.modal__btn:hover {
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}

.area__wrapper {
	height: 100%;
	width: 100%;
	background-color: #3ad0fa;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.area {
	width: 455px;
	height: 400px;
	list-style: none;
}

.area__head {
	color: #3c3c3c;
}

.tile {
	width: 20%;
	height: 20%;
	margin: 10px;
	position: relative;
	display: inline-block;
}

.area__footer {
	width: 80%;
	margin-top: 5%;
	display: flex;
	justify-content: space-between;
}

.area__footer>p {
	color: #ffffff;
	text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.hidden {
	display: none;
}