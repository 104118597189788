/* dice roller css  -- TODO: move to relivent file diceroller.css*/

.at-container {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 0 auto 40px;
    /* border: 1px solid #FFF; */
    perspective: 1000px;
    perspective-origin: 50% 100%;
}

.at-container *, .at-container *:before, .at-container *:after {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.cubefront {
    transform: translateZ(100px);
}

.cubeback {
    transform: rotateX(-180deg) translateZ(100px);
}

.cuberight {
    transform: rotateY(90deg) translateZ(100px);
}

.cubeleft {
    transform: rotateY(-90deg) translateZ(100px);
}

.cubetop {
    transform: rotateX(90deg) translateZ(100px);
}

.cubebottom {
    transform: rotateX(-90deg) translateZ(100px);
}

.cube {
    width: 100%;
    height: 100%;
    top: 25px;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 6s;
}

.cube:hover {
    cursor: pointer;
}

.cube div {
    background: hsla(0, 85%, 50%, 0.8);
    display: block;
    position: absolute;
    width: 200px;
    height: 100px;
    border: 2px solid #ab1a1a;
    margin: 0 auto;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 500%;
    text-align: center;
    padding: 50px 0;
}

.dot {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 15px;
}

.frontdot1 {
    top: 85px;
    left: 85px;
}

.backdot1 {
    top: 45px;
    left: 45px;
}

.backdot2 {
    top: 125px;
    left: 125px;
}

.rightdot1 {
    top: 45px;
    left: 45px;
}

.rightdot2 {
    top: 85px;
    left: 85px;
}

.rightdot3 {
    top: 125px;
    left: 125px;
}

.leftdot1 {
    top: 45px;
    left: 45px;
}

.leftdot2 {
    top: 45px;
    left: 125px;
}

.leftdot3 {
    top: 125px;
    left: 45px;
}

.leftdot4 {
    top: 125px;
    left: 125px;
}

.topdot1 {
    top: 45px;
    left: 45px;
}

.topdot2 {
    top: 45px;
    left: 125px;
}

.topdot3 {
    top: 85px;
    left: 85px;
}

.topdot4 {
    top: 125px;
    left: 45px;
}

.topdot5 {
    top: 125px;
    left: 125px;
}

.bottomdot1 {
    top: 45px;
    left: 45px;
}

.bottomdot2 {
    top: 45px;
    left: 85px;
}

.bottomdot3 {
    top: 45px;
    left: 125px;
}

.bottomdot4 {
    top: 125px;
    left: 45px;
}

.bottomdot5 {
    top: 125px;
    left: 85px;
}

.bottomdot6 {
    top: 125px;
    left: 125px;
}