/* By maxifjaved */

.right-align {
  width: 100%;
  float: left;
  background: #fff;
  position: relative;
}

.register-head h1 {
  margin: 0 0 3px;
  color: #333;
  font-size: 28px;
  line-height: 28px;
  text-transform: uppercase;
}

.register-head p {
  margin: 0;
  color: #8c8c8c;
}

.register-head {
  padding: 0;
  position: relative;
}

.btn-holo {
  display: inline-block;
  vertical-align: top;
  color: #1890ff;
  text-align: center;
  margin: 0 7px;
}

.right-footer {
  width: 100%;
  float: left;
  text-align: right;
  padding: 20px 0 0;
  overflow: hidden;
}

.right-footer label {
  display: block;
  line-height: 26px;
  text-align: left;
}

.fp {
  cursor: pointer;
  color: #1890ff;
  text-align: center;
}

/*popup buttons*/

.btn-success {
  width: 100%;
  border-radius: 20px;
  margin: 15px 0 0;
  outline: none;
  border-color: transparent;
  background: #0186ca;
}

.btn-cancel {
  width: 100%;
  border-radius: 20px;
  margin: 15px 0 0;
  outline: none;
  border-color: transparent;
  background: #dc3545;
}

.btn-success:active,
.btn-cancel:active,
.btn-success:focus,
.btn-cancel:focus,
.btn-success:active:focus,
.btn-cancel:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

.at-btnholder {
  width: 100%;
  float: left;
  padding: 20px 0 0;
}

.at-btnholder .fp {
  padding: 11px 0;
  display: inline-block;
  vertical-align: middle;
}

.at-forgotcontentheading {
  width: 100%;
  float: left;
  color: #262626;
  font-size: 14px;
  margin: 0 0 45px;
  line-height: 14px;
  text-align: center;
  text-transform: none;
  font-family: "Poppins", sans-serif;
}
.at-alerterror {
  padding: 12px;
  font-size: 14px;
  margin: 0 0 15px;
  line-height: 14px;
}

@media (max-width: 480px) {
  .register-head h1 {
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0 0;
  }
}
