.alert-msg{

}
.alert-holder{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    /*opacity: 0;*/
    z-index: 1010;
    font-size: 14px;
     margin: -100px 0 0;
    position: fixed;
    line-height: 14px;
    /*visibility: hidden;*/
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
   /* -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);*/
    
}
.at-openerrormesg{
   margin: 60px 0 0;
}
.at-danger,
.at-success{
   
    padding: 0 16px;
    background: #fff;
    line-height: 44px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    
}
.at-danger span,
.at-success span{
    display: block;
    position: relative;
    padding: 0 0 0 25px;
}
.at-danger span:after,
.at-success span:after{
    left: 0;
    top: 50%;
    color: #fff;
    width: 16px;
    height: 16px;
    font-size: 10px;
    content: '\f00c';
    margin: -8px 0 0;
    position: absolute;
    line-height: 15px;
    text-align: center;
    background: #52c41a;
    border-radius: 50%;
    font-family: 'FontAwesome';
}
.at-danger span:after{
    content: '\f12a';
    background: #f5222d;
}
