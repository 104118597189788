.form-group {
  position: relative;
}

.form-group label,
.form-group .form-control {
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}

.form-group.-animated {
  padding-top: 20px;
}

.form-group input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: Poppins, Serif;
  line-height: 28px;
  color: #262626;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.form-group input:focus {
  border-color: #ccc;
  -webkit-box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
}

.form-group label {
  position: absolute;
  z-index: 99;
  background: #fff;
  left: 14px;
  height: 20px;
  line-height: 20px;
  top: 29px;
  text-align: center;
  color: #929292;
  font-size: 12px;
  padding: 0 10px;
}

.form-group input:focus + label,
.form-group input + label:hover,
.form-group input:active + label,
.form-group input:focus-within + label,
.form-group input:hover + label,
.form-group input[type="”text”"][value]:not([value="””"]) {
  top: 8px;
}

.form-group.-animated.-active label {
  top: 8px;
  background-color: #fff;
}

.error-field {
  position: absolute;
  right: 0;
  top: 64px;
  background: #fff;
  z-index: 999;
  padding: 0 14px;
  color: #ff0000;
  font-size: 11px;
  line-height: 10px;
}

.popup-header {
  color: #fff;
  min-height: 142px;
  padding: 20px 15px;
  text-align: center;
  position: relative;
  margin: 0 0 30px;
  background: url(images/popup-bg.jpg) no-repeat;
}

.popup-header h5 {
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
}

.popup-header .popup-img {
  position: absolute;
  left: 50%;
  bottom: -50px;
  border-radius: 100px;
  overflow: hidden;
  background: #fff;
  padding: 20px;
  margin: 0 0 0 -50px;
}

.popup-header .popup-img img {
  display: inline-block;
  vertical-align: top;
  height: 60px;
}

.popup-content > div {
  border: 0 !important;
}

@media screen and (max-width: 919px) {
  .error-field {
    position: relative;
    right: auto;
    top: auto;
    background: #fff;
    z-index: 999;
    padding: 0 14px;
    color: #ff0000;
    font-size: 11px;
    line-height: 10px;
  }
}

.right-footer {
  text-align: center;
  padding: 20px 0 10px;
  overflow: hidden;
}

.right-footer label {
  display: block;
  line-height: 26px;
  text-align: left;
}

/*popup buttons*/

.btn-success {
  width: 100%;
  border-radius: 20px;
  margin: 15px 0 0;
  outline: none;
  border-color: transparent;
  background: #0186ca;
}

.btn-cancel {
  width: 100%;
  border-radius: 20px;
  margin: 15px 0 0;
  outline: none;
  border-color: transparent;
  background: #dc3545;
}

.btn-success:active,
.btn-cancel:active,
.btn-success:focus,
.btn-cancel:focus,
.btn-success:active:focus,
.btn-cancel:active:focus {
  box-shadow: none !important;
  outline: none !important;
}
