.at-paginations {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    font-size: 20px;
    line-height: 44px;
    list-style: none;
    padding: 30px 0 0;
    text-align: center;
}

.at-paginations li {
    padding: 0 10px;
    text-align: center;
    line-height: inherit;
    display: inline-block;
    vertical-align: middle;
    list-style-type: none;
}

.at-paginations li a {
    width: 40px;
    height: 40px;
    display: block;
    color: #525151;
    line-break: inherit;
    background: #f7f7f7;
    border-radius: 50%;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.at-paginations li a:hover {
    color: #fff;
    background: #29a2de;
}