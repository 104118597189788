.at-slide {
  width: 100%;
  float: left;
  cursor: move;
  padding: 15px;
  /* margin: 0 0 0 -2.5rem; */
  list-style: none;
}

.at-slideimg {
  margin: 0;
  width: 100%;
  float: left;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  background: #fff;
  /* border: 4px solid #29a2de; */
}

.at-slideimg div {
  width: auto;
  height: 159px;
  display: block;
  margin: 0 auto;
  max-width: none;
}

.at-btncloseslide {
  top: 5px;
  right: 5px;
  width: 20px;
  color: #fff;
  height: 20px;
  display: block;
  font-size: 13px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  background: #e04f5f;
}

.at-btncloseslide:hover,
.at-btncloseslide:focus {
  color: #fff;
}

.at-btnviewname {
  width: 100%;
  float: left;
  background: #223d49;
  position: relative;
  padding: 0 45px 0 14px;
  border-radius: 0 0 3px 3px;
}

.at-btnviewname span {
  float: left;
  color: #fff;
  width: 100%;
  opacity: 0.7;
  font-size: 11px;
  overflow: hidden;
  line-height: 31px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.at-btnview {
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.16);
  border-radius: 0 0 3px 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.activity-question-holder {
  /* max-height: 75vh; */
  /* position: relative; */
  /* overflow: scroll; */

  width: 100%;
  /* max-width: 250px; */
  height: 75vh;
  float: left;
  overflow: auto;
  position: relative;
}

.activity-question-holder ul {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  height: 100%;
  list-style: none;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
}

.activity-question-holder ul li {
  /* width: 250px; */
  /* float: none; */
  /* display: block; */
}
