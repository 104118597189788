.at-invitationpopup {
    max-width: 615px;
    width: 100%;
    margin: 80px auto;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
}

.at-popupheading {
    top: 30px;
    left: 50%;
    color: #fff;
    z-index: 99;
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.at-invitationpopupcontent {
    width: 100%;
    float: left;
}

.at-contentholder {
    max-width: 555px;
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    position: relative;
    margin: -60px auto 0;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.at-invitearea {
    width: 100%;
    float: left;
    padding: 30px;
    text-align: left;
}

.at-invitearea h4 {
    color: #000;
    font-size: 18px;
    margin: 0 0 20px;
    line-height: 18px;
    text-transform: none;
    font-family: 'Poppins', sans-serif;
}

.at-inputholder {
    width: 100%;
    float: left;
    position: relative;
}

.at-inputholder input {
    padding-right: 160px;
}

.at-inputholder button {
    top: 50%;
    right: 0;
    border: 0;
    box-shadow: none;
    margin: -14px 0 0;
    line-height: 48px;
    position: absolute;
}

.at-inputholder button:focus {
    outline: none;
    border: 0;
}

.at-tableholder {
    width: 100%;
    float: left;
}

.at-tableaddpoeple thead {
    background: #ececec;
    background: -moz-linear-gradient(top, #ececec 1%, #f7f7f7 100%);
    background: -webkit-linear-gradient(top, #ececec 1%, #f7f7f7 100%);
    background: linear-gradient(to bottom, #ececec 1%, #f7f7f7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ececec', endColorstr='#f7f7f7', GradientType=0);
}

.at-tableaddpoeple thead th, .at-tableaddpoeple tbody tr th, .at-tableaddpoeple tbody tr td {
    border: 0 !important;
}

.at-btndownloadpeople {
    color: #000;
    font-size: 14px;
    background: none;
    line-height: 14px;
    border: 0;
    box-shadow: none;
    background: none;
    cursor: pointer;
}

.at-btndownloadpeople:focus {
    border: 0;
    outline: 0;
}

.at-btndownloadpeople i, .at-btndownloadpeople span {
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
}

.at-btndownloadpeople i {
    opacity: 1;
    color: #70b1dc;
    margin: 0 3px 0 0;
}

.at-invitebyemail {
    width: 100%;
    float: left;
    padding: 20px 0 0;
}

.at-invitebyemail .at-contentholder {
    margin-top: 0;
}

.at-popupimg {
    width: 100%;
    float: left;
    margin: 0;
    position: relative;
    border-radius: 5px 5px 0 0;
}

.at-popupimg img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px 5px 0 0;
}

.at-btnarea {
    width: 100%;
    float: left;
    text-align: right;
    padding: 20px 30px 30px;
}

.at-themebtn {
    color: #fff;
    font-size: 16px;
    padding: 0 30px;
    overflow: hidden;
    line-height: 44px;
    text-align: center;
    position: relative;
    background: #686878;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

.at-themebtn:hover {
    color: #fff;
    -webkit-box-shadow: 0 5px 20px 0 rgba(1, 138, 205, 0.17);
    box-shadow: 0 5px 20px 0 rgba(1, 138, 205, 0.17);
}

.at-themebtn+.at-themebtn {
    margin: 0 0 0 15px;
}

.at-themebtn:before {
    top: 0;
    left: 0;
    width: 0;
    content: '';
    height: 100%;
    position: absolute;
    background: #0186ca;
    background: -moz-linear-gradient(left, #0186ca 0%, #00b9ff 100%);
    background: -webkit-linear-gradient(left, #0186ca 0%, #00b9ff 100%);
    background: linear-gradient(to right, #0186ca 0%, #00b9ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0186ca', endColorstr='#00b9ff', GradientType=1);
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

.at-themebtn:hover:before {
    width: 100%;
}

.at-themebtn span {
    z-index: 2;
    position: relative;
}

.at-searchformhead {
    float: right;
}