.content .head {
  background: #dbecf4;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding: 20px;
  margin: -15px -15px 50px;
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
}

.breadcrumbs {
  float: left;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 10px 0 0;
}

.breadcrumbs li a {
  display: block;
}

.profile-holder {
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
}

.profile-holder .heading {
  background: #02a2de;
  color: #fff;
  float: left;
  width: 100%;
  padding: 15px 40px;
}

.profile-holder .heading h2 {
  display: inline;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
}

.profile-holder .heading a {
  display: inline;
  color: inherit;
}

.profile-holder .left-side {
  width: 50%;
  float: left;
  padding: 20px;
  border-right: 1px solid #f1f1f1;
  box-shadow: 2px 10px 30px -12px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 2px 10px 30px -12px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 10px 30px -12px rgba(0, 0, 0, 0.5);
}

.profile-holder form {
  float: left;
  width: 100%;
}

.profile-holder .profile-img {
  overflow: hidden;
}

.profile-holder .profile-img input {
  display: none;
}

.profile-holder .profile-img input + label {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.profile-holder .profile-img input + label:after {
  z-index: 99;
  right: 5px;
  bottom: 5px;
  border-radius: 10px;
  content: "\f044";
  position: absolute;
  font-size: 13px;
  width: 24px;
  height: 24px;
  line-height: 26px;
  text-align: center;
  font-family: "FontAwesome";
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.profile-holder .profile-img input + label img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right-side {
  float: left;
  width: 50%;
  padding: 20px;
}

.right-side .profile-img {
  overflow: hidden;
  margin: 0 auto 20px;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  overflow: hidden;
  background: #ccc;
  box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.5);
}

.right-side .profile-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right-side .table {
  font-size: 14px;
}

.right-side .table td {
  padding: 10px 12px;
  border: 1px solid #f0f0f0;
}

.right-side .table td:first-child {
  color: #7f7f7f;
}

.btn-update {
  display: block;
  border: 0;
  border-radius: 20px;
  text-align: center;
  background: #0186ca;
  float: left;
  width: 100%;
  padding: 6px 0;
  color: #fff;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
}

.at-checkboxholder {
  width: 100%;
  float: left;
  position: relative;
  padding: 50px 0 0 50px;
}

.at-checkboxholder label {
  margin: 0;
  float: left;
  color: #02a2de;
  font-size: 23px;
  line-height: 25px;
  padding: 5px 0 5px 10px;
}

.at-btnholder {
  width: 100%;
  float: left;
  padding: 20px 0 0;
}

.closebtn {
  /* top: 50%; */
  /* right: 15px; */
  width: 36px;
  height: 36px;
  font-size: 18px;
  cursor: pointer;
  /* margin: -18px 0 0; */
  line-height: 36px;
  /* position: absolute; */
  border-radius: 50%;
  text-align: center;
  background: #e04f5f;
  color: #fff !important;
}

/******New Check Box*****/

/* .at-checkboxholder h2,
.at-checkboxholder label{
    float: none;
    display: inline-block;
    vertical-align: middle;
} */

input[type="checkbox"].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type="checkbox"].css-checkbox + label.css-label {
  padding-left: 30px;
  height: 25px;
  display: inline-block;
  line-height: 25px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 25px;
  vertical-align: middle;
  cursor: pointer;
}

input[type="checkbox"].css-checkbox:checked + label.css-label {
  background-position: 0 -25px;
}

label.css-label {
  background-image: url(./images/checkbox.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-group {
  position: relative;
}

.form-group label,
.form-group .form-control {
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}

.form-group.-animated {
  padding-top: 20px;
}

.form-group input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-family: Poppins, Serif;
  line-height: 28px;
  color: #262626;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.form-group input:focus {
  border-color: #ccc;
  -webkit-box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
}

.form-group label {
  margin: 0;
  top: 35px;
  left: 14px;
  z-index: 99;
  height: 20px;
  color: #a9a9a9;
  font-size: 12px;
  padding: 0 10px;
  background: #fff;
  line-height: 20px;
  text-align: center;
  position: absolute;
}

.form-group input:focus + label,
.form-group input + label:hover,
.form-group input:active + label,
.form-group input:focus-within + label,
.form-group input:hover + label,
.form-group input[type="”text”"][value]:not([value="””"]) {
  top: 8px;
}

.form-group input:focus {
  -webkit-box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
}

.form-group.-animated.-active label {
  top: 8px;
  background-color: #fff;
}

.error-field {
  position: absolute;
  right: 0;
  top: 64px;
  background: #fff;
  z-index: 999;
  padding: 0 14px;
  color: #ff0000;
  font-size: 11px;
  line-height: 10px;
}

input.error {
  border-color: #fdb8b8;
}

@media screen and (max-width: 919px) {
  .error-field {
    position: relative;
    right: auto;
    top: auto;
    background: #fff;
    z-index: 999;
    padding: 0 14px;
    color: #ff0000;
    font-size: 11px;
    line-height: 10px;
  }
}
