.at-popupcontainer {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1000;
        position: fixed;
        overflow-y: auto;
        background: rgba(0, 0, 0, 0.70);
        text-align: center;
        /* -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
            flex-direction: column; */
        -webkit-transition: all 600ms ease-in-out;
        -moz-transition: all 600ms ease-in-out;
        -ms-transition: all 600ms ease-in-out;
        -o-transition: all 600ms ease-in-out;
        transition: all 600ms ease-in-out;
        -webkit-transform: scale(0);
        transform: scale(0);
}

.at-openpopup {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
}