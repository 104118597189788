.ant-modal-header {
  text-align: center;
  padding: 22px 20px;
  position: relative;
  background: #02a2de;
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
}

.ant-modal-title {
  margin: 0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

.ant-modal-close-x {
  color: #fff;
}
