.preloaderholder {
    position: absolute;
    /* left: 20px; */
    top: 44px;
    z-index: 99;
    left: 45%;
    padding: 0 16px;
    background: #fff;
    line-height: 44px;
    border-radius: 4px;
    display: inline-block;
    /* vertical-align: middle; */
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    color: #00A2DA;
    margin: 5px 0;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Arial', sans-serif;
    font-size: 10px;
    letter-spacing: 2px;
}

.preloader .line {
    width: 1px;
    height: 12px;
    background: #00A2DA;
    margin: 0 1px;
    display: inline-block;
    animation: opacity 1000ms infinite ease-in-out;
}

.preloader .line-2 {
    animation-delay: 600ms;
}

.preloader .line-1 {
    animation-delay: 800ms;
}

.preloader .line-3 {
    animation-delay: 400ms;
}

.preloader .line-4 {
    animation-delay: 200ms;
}

.preloader .line-6 {
    animation-delay: 200ms;
}

.preloader .line-7 {
    animation-delay: 400ms;
}

.preloader .line-8 {
    animation-delay: 600ms;
}

.preloader .line-9 {
    animation-delay: 800ms;
}

@keyframes opacity {
    0% {
        opacity: 1;
        height: 15px;
    }
    50% {
        opacity: 0;
        height: 12px;
    }
    100% {
        opacity: 1;
        height: 15px;
    }
}