.at-btnclosetool {
  top: 50%;
  right: 15px;
  width: 36px;
  height: 36px;
  font-size: 18px;
  cursor: pointer;
  margin: -18px 0 0;
  line-height: 36px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: #e04f5f;
  color: #fff !important;
}

.box {
  top: 0%;
  left: 20%;
  width: 599px;
  height: 330px;
  background-image: url(images/protractor.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 11;
  display: block;
  position: absolute;
  border: 1px solid #02a2de;
  transform-origin: center bottom 0px;
  border-top-left-radius: 312px;
  border-top-right-radius: 317px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
  -webkit-transform: translate(200px, 200px);
  transform: translate(200px, 200px);
}

.handler {
  position: absolute;
  border: 1px solid #000;
  box-sizing: border-box;
  background: #28a1dc;
  width: 33px;
  height: 30px;
  cursor: pointer;
}

.st {
  top: 0;
  margin-top: -4px;
  left: 50%;
}

.sb {
  bottom: 0;
  margin-bottom: -4px;
  left: 50%;
}

.sl {
  left: 0;
  margin-left: -4px;
  top: 50%;
}

.sr {
  right: 0;
  margin-right: -4px;
  top: 50%;
}

.lt, .rt {
  top: 0;
  margin-top: -4px;
}

.lt {
  left: 0;
  margin-left: -4px;
}

.rt {
  right: 0;
  margin-right: -4px;
}

.lb, .rb {
  bottom: 0;
  margin-bottom: -4px;
}

.lb {
  left: 0;
  margin-left: -4px;
}

.rb {
  right: 0;
  margin-right: -4px;
}