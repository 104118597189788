/*============================================================================================
								CONTRIBUTE EDITOR STYLING
============================================================================================*/

.at-wrapper {
  height: 100%;
  /* overflow: hidden; */
  position: relative;
}

.at-wrapper::after {
  clear: both;
  content: "";
  display: block;
  overflow: hidden;
}

.at-bgmain {
  background: #e6e9eb;
}

.at-haslayout {
  width: 100%;
  float: left;
}

.at-main.at-bgmain {
  padding: 20px 0 0;
  height: 100vh;
}

/*==============================================
			Top Bar
==============================================*/

.at-topbar {
  width: 100%;
  float: left;
  z-index: 10;
  background: #fff;
  position: relative;
  padding: 7px 25px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
}

.at-toprightarea {
  float: left;
}

.at-logo {
  float: left;
  margin: 0 20px 0 0;
}

.at-logo a {
  display: block;
}

.at-logo a img {
  width: 100%;
  height: auto;
  display: block;
}

.at-btnback {
  float: left;
  color: #02a2de;
  display: block;
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
}

.at-btnback i {
  font-size: 20px;
  line-height: 15px;
  margin: 0 10px 0 0;
  line-height: inherit;
}

.at-btnback i,
.at-btnback span {
  display: inline-block;
  vertical-align: middle;
}

.at-btnback:hover,
.at-btnback:focus {
  color: #02a2de;
}

.at-notificationsbtns {
  margin: 0;
  float: right;
  padding: 11px 0;
  list-style: none;
}

.at-notificationsbtns li {
  float: left;
  padding: 0 18px;
  position: relative;
  line-height: normal;
  list-style-type: none;
}

.at-notificationsbtns li:last-child {
  padding-right: 0;
}

.at-notificationsbtns li a,
.at-notificationsbtns li a img {
  display: block;
}

.at-notificationsbtns li a img {
  width: 100%;
  height: auto;
}

.at-notificationsbtns li:first-child .at-themedropdown {
  right: 50px;
}

.at-notificationsbtns li:first-child:hover .at-themedropdown {
  top: 48px;
}

.at-btnholder {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.at-btnlacture {
  color: #fff;
  padding: 0 20px;
  font-size: 14px;
  line-height: 40px;
  background: #02a2de;
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #02a2de;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.at-btnlacture + .at-btnlacture {
  float: right;
}

.at-btnlacture i,
.at-btnlacture span {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}

.at-btnlacture i {
  margin: 0 8px 0 0;
}

.at-btnlacture:hover,
.at-btnlacture:focus {
  color: #02a2de;
  background: #fff;
  border-color: #02a2de;
}

.at-btnlacture:hover i,
.at-btnlacture:hover span {
  color: #02a2de;
}

/*==============================================
			Editor Content
==============================================*/

.at-editorecontentholder {
  /* max-width: 1850px; */
  width: 99%;
  /*float: left;*/
  background: #fff;
  margin: 10px auto 0;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background: #f7f7f7 url(./images/pattren.png) top left;
  background-repeat: repeat-x;
  background-repeat: repeat;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.at-contenthead {
  width: 100%;
  float: left;
  z-index: 9;
  padding: 3px 0 0;
  position: relative;
}

.at-mainbtnholder {
  left: 0;
  max-width: 540px;
  width: 100%;
  float: left;
  position: relative;
  background: #02a2de;
  border-radius: 0 5px 5px 0;
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
}

.at-close {
  left: -488px;
}

.at-mainbtnholder ul {
  margin: 0;
  padding: 12px;
  font-size: 18px;
  list-style: none;
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.at-mainbtnholder ul li {
  float: left;
  padding: 0 13px;
  line-height: inherit;
  list-style-type: none;
}

.at-mainbtnholder ul li:first-chlid {
  padding-left: 0;
}

.at-mainbtnholder ul li a {
  color: #fff;
  display: block;
}

.at-mainbtnholder ul li a img {
  width: 100%;
  height: auto;
  display: block;
}

.at-mainbtnholder ul.at-homebtns {
  margin: 0;
  padding-right: 12px;
  padding-left: 0;
  border-right: 1px solid #0397cf;
}

.at-mainbtnholder ul.at-redobtns {
  padding-left: 5px;
  padding-right: 12px;
  border-right: 1px solid #0397cf;
}

.at-mainbtnholder ul.at-actionbtns {
  padding-left: 0;
  border-right: 1px solid #0397cf;
}

.at-mainbtnholder ul.at-btndownload {
  padding: 0 10px;
  border-right: 1px solid #0397cf;
}

.at-mainbtnholder ul.at-btncloseholder {
  cursor: pointer;
}

.at-mainbtnholder ul.at-btncloseholder li {
  padding: 0 8px;
}

.at-mainbtnholder.at-close ul.at-btncloseholder li a i:before {
  content: "\f0da";
}

.at-btnnextprev {
  float: right;
  list-style: none;
  padding: 0 20px 0 0;
}

.at-btnnextprev li {
  float: left;
  padding: 0 6px;
  list-style-type: none;
}

.at-btnnextprev li:last-child {
  padding-right: 0;
}

.at-btnprev,
.at-btnnext {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 3px;
  text-align: center;
  background: #f2f2f2;
  color: #cdcdcd !important;
  border: 1px solid #cdcdce;
}

.at-btnprev:hover,
.at-btnnext:hover {
  background: #02a2de;
  border-color: #02a2de;
  color: #fff !important;
}

.at-toolsholder {
  margin: 0;
  padding: 0;
  top: 175px;
  left: 20px;
  width: 120px;
  float: left;
  z-index: 10;
  background: #fff;
  position: absolute;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.at-dragable {
  width: 100%;
  float: left;
  height: 35px;
  cursor: move;
  position: relative;
  border-bottom: 4px solid #f5f3f3;
}

.at-dragable:before {
  top: 50%;
  left: 50%;
  height: 5px;
  width: 35px;
  content: "";
  position: absolute;
  background: url(images/icons/icon-09.png) no-repeat;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.at-tools {
  margin: 0;
  width: 100%;
  float: left;
  padding: 0;
  list-style: none;
  font-size: 20px;
  line-height: 20px;
  position: relative;
  border-bottom: 4px solid #f5f3f3;
}

/* .at-tools:before {
	left: 50%;
	bottom: 0;
	content: '';
	width: 25px;
	height: 1px;
	position: absolute;
	background: #dfdfdf;
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
} */

.at-tools li {
  width: 50%;
  float: left;
  position: relative;
  text-align: center;
  line-height: inherit;
  list-style-type: none;
}

.at-tools li:after {
  right: 0;
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-bottom: 0 solid #ccc;
  border-left: 5px solid transparent !important;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-tools li:hover:after {
  border-bottom-width: 5px;
}

.at-tools li a {
  color: #ccc;
  display: block;
  padding: 10px 0;
  position: relative;
}

.at-tools li a:before {
  top: 0;
  left: 0;
  height: 0;
  width: 3px;
  content: "";
  position: absolute;
  background: #02a2de;
}

.at-tools li a:hover:before,
.at-tools li.at-active a:before {
  height: 100%;
}

.at-tools li a img {
  width: auto;
  opacity: 0.4;
  height: auto;
  display: block;
  margin: 0 auto;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.at-tools li a:hover img,
.at-tools li.at-active a img {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.at-btnfullscreen {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  list-style: none;
  font-size: 20px;
  line-height: 20px;
  position: relative;
  border-bottom: 4px solid #f5f3f3;
}

.at-btnfullscreen li {
  width: 50%;
  float: left;
  text-align: center;
  line-height: inherit;
  list-style-type: none;
}

.at-btnfullscreen li a {
  color: #ccc;
  display: block;
  padding: 13px 0;
  position: relative;
}

.at-btnfullscreen li a:before {
  top: 0;
  left: 0;
  height: 0;
  width: 3px;
  content: "";
  position: absolute;
  background: #02a2de;
}

.at-btnfullscreen li a:hover:before,
.at-btnfullscreen li.at-active a:before {
  height: 100%;
}

.at-btnfullscreen li a img {
  width: auto;
  opacity: 0.4;
  height: auto;
  display: block;
  margin: 0 auto;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.at-btnfullscreen li a:hover img,
.at-btnfullscreen li.at-active a img {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.at-btnscollapholder {
  right: 0;
  top: 50%;
  z-index: 10;
  width: 130px;
  padding: 10px 0;
  position: fixed;
  background: #02a2de;
  border: 2px solid #02a2de;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
}

.at-btnscollapholder.at-closetools {
  right: -128px;
}

.at-btncolosetools {
  top: 50%;
  right: 100%;
  color: #fff;
  width: 25px;
  height: 87px;
  line-height: 87px;
  text-align: center;
  position: absolute;
  background: #02a2de;
  border-radius: 10px 0 0 10px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
}

.at-btnscollapholder.at-closetools .at-btncolosetools i,
.at-btnscollapholder.at-closetools .at-btncolosetools i:before {
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
}

.at-btnscollapholder.at-closetools .at-btncolosetools i:before {
  content: "\f0d9";
}

.at-btncolosetools:hover,
.at-btncolosetools:focus {
  color: #fff;
}

.at-btncollaps {
  width: 100%;
  float: left;
}

.at-btncollaps > a {
  color: #fff;
  opacity: 0.5;
  display: block;
  padding: 0 10px;
  font-size: 12px;
  line-height: 40px;
  position: relative;
  text-transform: uppercase;
}

.at-btncollaps > a:before {
  top: 0;
  right: 10px;
  color: #fff;
  opacity: 0.5;
  font-size: 12px;
  content: "\f105";
  position: absolute;
  line-height: inherit;
  font-family: "FontAwesome";
}

.at-btncollaps > a:hover:before,
.at-btncollaps > a.at-active:before {
  opacity: 1;
  color: #02a2de;
  content: "\f107";
}

.at-btncollaps > a span {
  display: block;
  position: relative;
  padding: 0 0 0 20px;
}

.at-btncollaps > a span:after {
  top: 50%;
  left: 0;
  width: 9px;
  content: "";
  opacity: 0.5;
  height: 11px;
  position: absolute;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.at-btncollaps.at-btnscience a span:after {
  background: url(images/tools-icons/icon-12.png) no-repeat;
}

.at-btncollaps.at-btnmath a span:after {
  background: url(images/tools-icons/icon-13.png) no-repeat;
}

.at-btncollaps.at-btngeography a span:after {
  background: url(images/tools-icons/icon-14.png) no-repeat;
}

.at-btncollaps.at-btnmusic a span:after {
  background: url(images/tools-icons/icon-15.png) no-repeat;
}

.at-btncollaps.at-btnlanguage a span:after {
  background: url(images/tools-icons/icon-16.png) no-repeat;
}

.at-btncollaps.at-btnscience a:hover span:after {
  opacity: 1;
}

.at-btncollaps.at-btnscience a:hover span:after {
  background: url(images/tools-icons/icon-17.png) no-repeat;
}

.at-btncollaps.at-btnmath a:hover span:after {
  background: url(images/tools-icons/icon-18.png) no-repeat;
}

.at-btncollaps.at-btngeography a:hover span:after {
  background: url(images/tools-icons/icon-19.png) no-repeat;
}

.at-btncollaps.at-btnmusic a:hover span:after {
  background: url(images/tools-icons/icon-20.png) no-repeat;
}

.at-btncollaps.at-btnlanguage a:hover span:after {
  background: url(images/tools-icons/icon-21.png) no-repeat;
}

.at-btncollaps > a:hover,
.at-btncollaps > a:focus {
  opacity: 1;
  color: #02a2de;
  background: #fff;
}

.at-subtools {
  padding: 0;
  width: 100%;
  float: left;
  display: none;
  list-style: none;
  position: static;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.at-btncollaps.at-openclose .at-subtools {
  display: block;
}

.at-subtools li {
  margin: 0 -2px;
  min-height: 56px;
  line-height: 56px;
  padding: 15px 20px;
  text-align: center;
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
}

.at-subtools li a {
  display: block;
}

.at-subtools li a img {
  width: 100%;
  height: auto;
  display: block;
}

.at-canvasholer {
  width: 100%;
  float: left;
  /* margin: -30px 0 0; */
  margin: 0 0 0;
  position: relative;
}

.at-colorsubmenu {
  top: 10px;
  left: 100%;
  opacity: 1;
  z-index: 9;
  width: 225px;
  background: #fff;
  visibility: visible;
  position: absolute;
  border-radius: 2px;
  border: 2px solid #53bee8;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-colorsubmenu i {
  color: #53bee8;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 2px;
}

/* .at-tools li:hover .at-colorsubmenu {
	top: 10px;
	opacity: 1;
	visibility: visible;
} */

.at-colorholder {
  float: left;
  padding: 4px;
  width: 33.33%;
}

.at-color {
  float: left;
  width: 100%;
  height: 27px;
  background: #000;
  border-radius: 2px;
}

.at-shapesholder {
  top: 100px;
  left: 100%;
  opacity: 0;
  width: 145px;
  outline: none;
  box-shadow: none;
  background: #fff;
  visibility: hidden;
  position: absolute;
  border-radius: 2px;
  border: 2px solid #53bee8;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-tools li:hover .at-shapesholder {
  top: 10px;
  opacity: 1;
  visibility: visible;
}

.at-shapebutton {
  border: 0;
  width: 100%;
  float: left;
  color: #262626;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  background: none;
  box-shadow: none;
  text-align: left;
  padding: 11px 15px;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-shapebame,
.at-shapeimg {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-shapebame {
  padding: 0 0 0 15px;
}

.at-shapebutton:hover .at-shapebame {
  color: #02a2de;
}

.at-searchimgholder {
  top: 10px;
  left: 100%;
  opacity: 1;
  padding: 0;
  width: 165px;
  outline: none;
  box-shadow: none;
  background: #fff;
  visibility: visible;
  position: absolute;
  border-radius: 2px;
  list-style: none;
  border: 2px solid #53bee8;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

/* .at-tools li:hover .at-searchimgholder {
	top: 10px;
	opacity: 1;
	visibility: visible;
} */

.at-searchimgholder li {
  width: 100%;
  float: left;
  text-align: left;
  list-style-type: none;
}

.at-searchimgholder li img {
  margin: 0 10px 0 0;
}

.at-searchimgholder li input {
  display: none;
}

.at-searchimgholder li label {
  margin: 0;
  width: 100%;
  float: left;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-searchimgholder li label i {
  margin: 0 10px 0 0;
}

.at-searchimgholder li label i,
.at-searchimgholder li label span {
  color: #262626;
  display: inline-block;
  vertical-align: middle;
}

.at-searchimgholder li:hover label {
  color: #fff;
  background: #02a2de;
}

.at-searchimgholder li:hover label i,
.at-searchimgholder li:hover label span {
  color: #fff;
}

/* maxifjaved  please update it accordingly*/

/***********************************************
this class is to handle nav button and video controlls
i.e if we have an image slide the it is being used
to hide video/pdf nav buttons
***************************************************/

.hide {
  display: none;
}

.at-imagesearchoholder {
  top: 150px;
  left: 140px;
  z-index: 10;
  opacity: 0;
  max-width: 360px;
  width: 100%;
  visibility: hidden;
  position: absolute;
}

.at-openlivesearch {
  opacity: 1;
  visibility: visible;
}

.at-btnclosesearchimg {
  border: 0;
  z-index: 5;
  top: -12px;
  padding: 0;
  width: 24px;
  color: #fff;
  right: -12px;
  height: 24px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  box-shadow: none;
  line-height: 24px;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  background: #d94040;
}

a.at-btnclosesearchimg:not([href]):not([tabindex]) {
  color: #fff;
  text-decoration: none;
}

.at-searchholder {
  width: 100%;
  float: left;
  position: relative;
}

.at-searchholder button {
  top: 0;
  right: 0;
  border: 0;
  z-index: 3;
  color: #999;
  width: 40px;
  height: 45px;
  box-shadow: none;
  line-height: 45px;
  position: absolute;
  text-align: center;
  background: none;
}

.at-searchholder input {
  height: 45px;
  box-shadow: none;
  border: 1px solid #c8c8c8;
  padding: 10px 46px 10px 10px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.at-searchholder input::placeholder {
  color: #c5c5c5;
}

.at-searchimages {
  padding: 0;
  height: 455px;
}

/*.at-searchimages::-webkit-scrollbar { display: none; }*/

.at-searchimages li {
  width: 50%;
  float: left;
  padding: 5px;
  list-style-type: none;
}

.at-liveimg {
  margin: 0;
  width: 100%;
  float: left;
  background: #000;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  border: 4px solid #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.at-liveimg img {
  width: 100%;
  height: auto;
  display: block;
  border-right: 2px;
}

.at-liveimg:hover {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.at-liveimg:after {
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  content: "";
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.at-liveimg:hover:after {
  opacity: 1;
  visibility: visible;
}

.at-addimage {
  top: 50%;
  left: 50%;
  opacity: 0;
  width: 90%;
  height: 90%;
  z-index: 99;
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  border-radius: 2px;
  border: 1px solid #01516f;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -moz-transform: translateX(-50px) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.at-addimage i,
.at-addimage span {
  color: #fff;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}

.at-addimage i {
  font-weight: 400;
  margin: 0 0 10px;
}

.at-liveimg:hover .at-addimage {
  opacity: 1;
  visibility: visible;
}

.at-videocontrols {
  bottom: 0;
  left: 50%;
  margin: 0;
  padding: 0 5px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  background: #fff;
  position: absolute;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.at-videocontrols ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}

.at-videocontrols ul li {
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
}

.at-videocontrols ul li:nth-child(2),
.at-videocontrols ul li:nth-child(3) {
  float: left;
}

.at-videocontrols ul li:nth-child(4),
.at-videocontrols ul li:nth-child(5),
.at-videocontrols ul li:nth-child(6),
.at-videocontrols ul li:last-child {
  float: right;
}

.at-inputrange {
  width: 100%;
}

.at-videocontrols .at-inputrange li {
  width: 100%;
}

.at-inputrange input {
  width: 100%;
  float: left;
}

input[type="range"] {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  min-height: 8px;
  cursor: pointer;
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 17px;
  height: 8px;
  background-image: linear-gradient(#02a2de, #02a2de);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

input[type="range"]::-moz-range-track {
  border: none;
  height: 0.5em;
  background: #b1b4b5;
}

input[type="range"]::-ms-track {
  border: none;
  height: 0.5em;
  border-radius: 0.25em;
  background: #d8d8d8;
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  margin: -0.25em;
  border: none;
  /* Firefox, IE */
  width: 17px;
  height: 17px;
  background: #058fd8;
  border-radius: 50px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  /* Firefox, IE */
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  cursor: ew-resize;
}

input[type="range"]::-ms-thumb {
  border: none;
  /* Firefox, IE */
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  border-radius: 5em;
  background: transparent;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

input[type="range"] /deep/ #track {
  position: relative;
}

input[type="range"] /deep/ #track:before,
input[type="range"] /deep/ #track:after {
  position: absolute;
  top: -0.25em;
  height: 1em;
  transform: scale(0.8);
  color: dimgrey;
  font-weight: 600;
  line-height: 1em;
}

input[type="range"] /deep/ #track:before {
  right: calc(100% + 0.75em);
}

input[type="range"] /deep/ #track:after {
  left: calc(100% + 0.75em);
}

input[type="range"]::-moz-range-track {
  background-image: linear-gradient(#bfdfff, #bfdfff);
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.at-btnplaystop {
  float: left;
}

.at-btnvolumupdown,
.at-fullscreenbtn {
  float: right;
  border-left: 1px solid #d8d8d8;
}

/*.at-btnvolumupdown li + li{border-right: 1px solid #d8d8d8;}*/

.at-videocontrols ul li button {
  border: 0;
  width: 50px;
  height: 50px;
  color: #b2b2b2;
  cursor: pointer;
  outline: none;
  border: 0;
  font-size: 20px;
  box-shadow: none;
  background: none;
  line-height: 50px;
}

.gallery-search {
  border: 0;
  z-index: 5;
  top: -12px;
  padding: 0;
  width: 24px;
  color: #fff;
  left: -12px;
  height: 24px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  box-shadow: none;
  line-height: 24px;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  background: #d94040;
}

a.gallery-search:not([href]):not([tabindex]) {
  color: #fff;
  text-decoration: none;
}

.at-activecolor,
.at-activecolor:hover {
  border: 3px solid #fff;
}

/*==============================================
			Chat Style
==============================================*/

.at-chatpopup {
  z-index: 5;
  width: 60px;
  height: 60px;
  right: 30px;
  bottom: 30px;
  position: fixed;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

/*.at-chatopenclosebtn{
	top: 0;
	right: 0;
	width: 30px;
	height: 30px;
	display: block;
	line-height: 30px;
	border-radius: 3px;
	text-align: center;
	position: absolute;
	background: #02a2de;
	color: #fff !important;
}*/

.at-chatclientimg {
  width: 70px;
  height: 70px;
  cursor: pointer;
  overflow: hidden;
  line-height: 70px;
  position: relative;
  border-radius: 50%;
  text-align: center;
  background: #02a1dc url(images/chat-icon.png) no-repeat;
  background-size: contain;
  background-position: center center;
  border: 2px solid #fff;
  -webkit-box-shadow: 0 0 25px 0 rgba(23, 103, 141, 0.11);
  box-shadow: 0 0 25px 0 rgba(23, 103, 141, 0.11);
}

.at-chatclientimg img {
  width: auto;
  height: auto;
  display: block;
  margin: 15px auto;
}

.at-chatbox {
  right: 0;
  width: 320px;
  bottom: 75px;
  overflow: hidden;
  background: #fff;
  max-height: 460px;
  /*padding: 30px 0 0;*/
  background: transparent;
  position: absolute;
  border-radius: 3px 3px 0 3px;
  -webkit-box-shadow: 0 0 50px 0 rgba(23, 103, 141, 0.11);
  box-shadow: 0 0 50px 0 rgba(23, 103, 141, 0.11);
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.at-closechat {
  max-height: 0;
}

.at-clientnameandstatus {
  color: #fff;
  width: 100%;
  float: left;
  padding: 15px 20px;
  background: #02a2de;
  border-radius: 3px 3px 0 0;
}

.at-clientnameandstatus span {
  float: left;
  font: 500 14px/20px "Poppins", Arial, Helvetica, sans-serif;
}

.at-chatgrabimg {
  float: right;
  width: auto;
  height: auto;
  display: block;
  padding: 10px 0;
}

.at-btnclosechat {
  color: #fff;
  float: right;
  width: 13px;
  height: 13px;
  margin: 3px 0 0;
  font-size: 14px;
  line-height: 13px;
  text-align: right;
}

.at-btnclosechat:hover,
.at-btnclosechat:focus {
  color: #fff;
}

.at-chathistory {
  width: 100%;
  float: left;
  background: #fff;
  padding: 10px;
  overflow-y: scroll;
}

.at-scrollbar {
  width: 100%;
  float: left;
  height: 250px;
  /* overflow-y: scroll; */
}

.at-scrollbar::-webkit-scrollbar {
  display: none;
}

.at-chathistory a {
  color: #55acee;
}

.at-clientmessage {
  width: 100%;
  float: left;
  text-align: left;
}

.at-messagebox {
  width: 100%;
  float: left;
}

.at-clientmessage + .at-clientmessage {
  padding: 14px 0 0;
}

.at-userimg {
  margin: 0;
  width: 30px;
  float: left;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.at-userimg img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 50%;
}

.at-chatcontent {
  padding: 10px;
  overflow: hidden;
  background: #f2fbff;
  border-radius: 10px;
}

.at-messagebox p {
  margin: 0;
  float: left;
  color: #000;
  font-size: 14px;
  line-height: 14px;
}

.at-imgattched {
  margin: 0;
  width: 100%;
  float: left;
  margin: -7px;
  list-style: none;
  padding: 20px 0 0;
}

.at-imgattched li {
  width: 50%;
  float: left;
  padding: 7px;
  list-style-type: none;
}

.at-imgattched li figure {
  margin: 0;
  width: 100%;
  float: left;
  position: relative;
  border-radius: 5px;
}

.at-imgattched li figure img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

.at-btndelimg {
  top: 5px;
  border: 0;
  right: 5px;
  padding: 0;
  width: 16px;
  color: #fff;
  height: 16px;
  font-size: 8px;
  cursor: pointer;
  box-shadow: none;
  line-height: 16px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  background: #ff0000;
}

.at-messagestatus {
  width: 100%;
  float: left;
  padding: 5px 0;
}

.at-emailadd {
  color: #000;
  float: right;
  opacity: 0.3;
  position: relative;
  padding: 0 16px 0 0;
  font: 500 10px/10px "Poppins", Arial, Helvetica, sans-serif;
}

.at-supportmessage {
  width: 100%;
  float: left;
  text-align: right;
}

.at-supportmessage .at-messagebox {
  float: right;
  border-radius: 10px 10px 0 10px;
}

.at-supportmessage .at-messagestatus time {
  float: right;
}

.at-supportmessage .at-messagestatus time {
  padding: 0;
  float: right;
}

.at-supportmessage .at-messagestatus time:before {
  display: none;
}

.at-formchat {
  width: 100%;
  float: left;
  background: #fff;
  position: relative;
  padding: 0 20px 5px;
}

.at-formchat input {
  border: 0;
  width: 100%;
  float: left;
  resize: none;
  height: 50px;
  font-size: 13px;
  border-radius: 0;
  line-height: 16px;
  padding: 10px 30px 10px 50px;
  position: relative;
  border-top: 1px solid #d5e1ea;
}

.at-formchat input:focus {
  outline: none;
  box-shadow: none;
}

.at-formchat i {
  top: 51px;
  z-index: 3;
  left: 15px;
  color: #000;
  width: 30px;
  height: 40px;
  opacity: 0.4;
  text-align: left;
  position: absolute;
  line-height: 40px;
}

.at-formchat button {
  top: 51px;
  border: 0;
  right: 20px;
  width: 30px;
  height: 40px;
  color: #458dfe;
  cursor: pointer;
  box-shadow: none;
  background: none;
  position: absolute;
}

.at-formchat button:focus {
  border: 0;
  outline: none;
}

.at-videocallscreen {
  top: 50%;
  left: 50%;
  z-index: 99;
  width: 432px;
  display: none;
  position: absolute;
  border-radius: 5px;
  border: 1px solid #9fd8f1;
  background: rgba(30, 30, 30, 0.84);
  -webkit-box-shadow: 0 0 25px 0 rgba(1, 138, 205, 0.2);
  box-shadow: 0 0 25px 0 rgba(1, 138, 205, 0.2);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.at-openvideoscreen {
  display: block;
}

.at-videoimg {
  margin: 0;
  width: 100%;
  float: left;
  /* position: relative; */
  border-radius: 5px;
}

.at-videoimg video {
  width: 100%;
  float: left;
  border-radius: 5px;
}

.at-videoimg img {
  width: auto;
  height: 100%;
  display: block;
  border-right: 5px;
}

.at-videoimg figcaption {
  /* z-index: 99; */
  /* bottom: 0; */
  /* left: 50%; */
  /* max-width: 395px; */
  /* width: 100%; */
  /* padding: 10px; */
  /* min-height: 50px; */
  /* margin: 0 auto; */
  /* position: absolute; */
  /* width: 100%;
	  height: 20px; */
  /* border-radius: 5px 5px 0 0;
	  background: rgba(30, 30, 30, 0.84);
	  -moz-transform: translateX(-50%);
	  -webkit-transform: translateX(-50%);
	  -o-transform: translateX(-50%);
	  -ms-transform: translateX(-50%);
	  transform: translateX(-50%); */
}

.at-videoimg figcaption h3 {
  /* margin: 0;
	  color: #fff;
	  float: left;
	  font-size: 14px;
	  line-height: 17px; */
}

.at-videoimg figcaption h3 span {
  /* opacity: 0.50;
	  display: block;
	  font-size: 12px;
	  font-weight: 300;
	  line-height: 15px; */
}

.at-callactions {
  /* padding: 0; */
  float: right;
  list-style: none;
  margin: 6px;
  position: absolute;
  z-index: 9;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.at-callactions li {
  float: left;
  padding: 0 5px;
  list-style-type: none;
}

.at-callactions li a {
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 15px;
  display: block;
  line-height: 33px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  background: #02a2de;
  border: 1px solid #fff;
}

.at-callactions li a:not([href]):not([tabindex]) {
  color: #fff;
  text-decoration: none;
}

.at-callactions li.at-callend a {
  background: #e51323;
}

.at-newslidesholder {
  /* left: 30px; */
  right: 110px;
  /* bottom: 0px; */
  color: #fff;
  z-index: 99;
  width: 70px;
  height: 70px;
  bottom: 30px;
  font-size: 22px;
  position: fixed;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid;
  background: #02a2de;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.at-newslidesholder i {
  /* color: red; */
  font-size: 36px;
  /* margin: auto; */
  /* float: left; */
  padding: 23%;
}

.at-opencloseslidearea {
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 50%;
  background: #e04f5f;
  position: absolute;
  color: #fff !important;
}

/*
	by @maxifjaved
	slide preview
 */

.at-slidesarea {
  left: 0;
  bottom: 0;
  padding: 16px 0 0 0;
  width: 0;
  height: 217px;
  position: fixed;
  overflow: hidden;
  background: #294957;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.at-slidescontent {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  padding: 0 25px 15px;
}

.at-togglepreview {
  width: 100%;
}

.at-slidesholder {
  width: 100%;
  overflow-x: scroll;
  float: left;
  height: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.at-slideheading {
  width: 100%;
  float: left;
  padding: 20px 0 20px;
}

.at-slideheading h3 {
  margin: 0;
  color: #fff;
  opacity: 0.5;
  font-size: 14px;
  line-height: 17px;
}

.at-slidesholder ul {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: scroll;
  height: 175px;
  list-style: none;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.at-slidesholder ul li {
  width: 203px;
  float: none;
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  white-space: nowrap;
}

.at-slide {
  width: 20%;
  float: left;
  cursor: move;
  padding: 0 15px;
}

.at-slideimg {
  margin: 0;
  width: 100%;
  float: left;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  background: #fff;
  border: 4px solid #29a2de;
}

.at-slideimg img {
  width: 170px;
  height: 140px;
}

.at-btnviewname {
  width: 100%;
  float: left;
  background: #223d49;
  position: relative;
  padding: 0 45px 0 14px;
  border-radius: 0 0 3px 3px;
}

.at-btnviewname span {
  float: left;
  color: #fff;
  width: 100%;
  opacity: 0.7;
  font-size: 11px;
  overflow: hidden;
  line-height: 31px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.at-btnview {
  top: 0;
  right: 0;
  width: 42px;
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.16);
  border-radius: 0 0 3px 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.at-notibadge {
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  color: #fff;
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  background: #ff0000;
}

.at-greenbadge {
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  color: #fff;
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  background: #097425;
}

.nsg-colleborating {
  background: #097425;
  border-radius: 4px;
}

.at-themedropdown {
  right: 0;
  opacity: 1;
  top: 40px;
  width: 500px;
  background: #fff;
  visibility: visible;
  border-radius: 2px;
  position: absolute;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-themedropdown:after {
  width: 0;
  height: 0;
  top: -10px;
  right: 16px;
  content: "";
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #02a1dd;
}

/* .at-notificationsbtns li:hover .at-themedropdown {
	top: 40px;
	opacity: 1;
	visibility: visible;
} */

.at-dropdownhead {
  width: 100%;
  float: left;
  position: relative;
  padding: 15px 20px;
  background: #02a1dd;
  border-radius: 2px 2px 0 0;
  -webkit-box-shadow: 0 5px 20px 0 rgba(2, 161, 221, 0.2);
  box-shadow: 0 5px 20px 0 rgba(2, 161, 221, 0.2);
}

.at-dropdownhead h2 {
  margin: 0;
  color: #fff;
  opacity: 0.8;
  font-size: 16px;
  line-height: 16px;
}

.at-totalnotifications {
  top: 50%;
  right: 20px;
  color: #02a1dd;
  padding: 0 6px;
  display: block;
  font-size: 12px;
  font-weight: 600;
  background: #fff;
  margin: -9px 0 0;
  line-height: 17px;
  position: absolute;
  border-radius: 20px;
}

.at-dropdowncontentholder {
  width: 100%;
  float: left;
  height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
}

.at-joinhead {
  float: left;
  opacity: 0.7;
  color: #959595;
  font-size: 12px;
  padding: 0 15px;
  line-height: 26px;
  background: #f6f6f6;
  border-radius: 0 20px 20px 0;
}

.at-notifications {
  width: 100%;
  float: left;
  padding: 18px 0 0;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}

.at-notification {
  width: 100%;
  float: left;
  padding: 10px 15px;
}

.at-notification + .at-notification {
  border-top: 1px solid #f2f2f2;
}

.at-requestjoin {
  box-shadow: none;
}

.at-screensholder {
  width: 100%;
  float: left;
  margin: -5px;
  padding: 25px;
}

.at-screenrequest {
  margin: 1%;
  width: 48%;
  float: left;
  padding: 5px;
  border: 1px solid #29a1dd;
}

.at-screenrequest a {
  margin: 0;
  width: 10%;
  float: left;
  position: relative;
}

.nsg-comparelable {
  margin: 0;
  width: 90%;
  float: left;
  padding: 5px 0 0 0;
  border-radius: 2px;
  /* position: relative; */
  border: 2px solid #fff;
}

.nsg-qualitylabel {
  margin: 0;
  width: 33%;
  float: left;
  padding: 5px 0 0 0;
  border-radius: 2px;
  position: relative;
  border: 2px solid #fff;
}

.at-screenimg {
  margin: 0;
  width: 100%;
  float: left;
  height: 100%;
  z-index: 10;
  background: #000;
  position: relative;
  border-radius: 2px;
}

.at-screenimg img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 2px;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-btnfullscreentwo {
  top: 50%;
  right: 15px;
  display: block;
  cursor: pointer;
  position: absolute;
}

.at-btnfullscreentwo img {
  width: auto;
  height: auto;
  margin: -6px 0 0;
  display: block;
}

.at-positionstatic {
  position: static !important;
}

.at-fullscreen {
  right: 0;
  width: 96%;
}

.at-dropdownwidthheight {
  height: 650px;
}

.at-fullscreen:after {
  display: none;
}

.at-screenimg video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.at-screenimg input {
  float: left;
  position: absolute;
  left: 10px;
}

@media (max-width: 1680px) {
  .at-contenthead {
    left: 0;
    /* position: absolute; */
  }
}

@media (max-width: 640px) {
  .at-btnholder {
    width: 110px;
    max-width: none;
  }

  .at-btnlacture {
    padding: 0;
    width: 50px;
    height: 50px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
  }

  .at-btnlacture span {
    display: none;
  }

  .at-btnlacture i {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .at-btnholder {
    width: 75px;
  }

  .at-btnlacture {
    width: 35px;
    height: 35px;
    line-height: 25px;
  }

  .at-notificationsbtns li {
    padding: 0 8px;
  }

  .at-close {
    left: -260px;
  }

  .at-mainbtnholder {
    max-width: 300px;
  }

  .at-mainbtnholder ul {
    border: 0;
  }

  .at-mainbtnholder ul {
    padding: 8px;
  }

  .at-mainbtnholder ul.at-btndownload {
    padding: 8px;
    border-top: 1px solid #0397cf;
  }

  .at-mainbtnholder ul.at-btncloseholder {
    top: 50%;
    right: 0;
    margin: -20px 0 0;
    position: absolute;
  }

  .at-mainbtnholder ul.at-actionbtns {
    border-top: 1px solid #0397cf;
  }

  .at-mainbtnholder ul.at-homebtns,
  .at-mainbtnholder ul.at-redobtns,
  .at-mainbtnholder ul.at-actionbtns,
  .at-mainbtnholder ul.at-btndownload {
    border-right: 0;
  }
}

.at-btnpdf {
  margin: 0;
  left: 50%;
  padding: 0;
  width: auto;
  bottom: 10px;
  list-style: none;
  position: absolute;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.at-btnpdf li {
  float: left;
  list-style-type: none;
}

.at-btnpdf li + li {
  padding: 0 0 0 10px;
}

.at-btnpdf li button {
  border: 0;
  color: #999;
  display: block;
  cursor: pointer;
  font-size: 18px;
  padding: 0 20px;
  font-weight: 400;
  box-shadow: none;
  line-height: 38px;
  text-align: center;
  border-radius: 5px;
  background: #f2f2f2;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.at-btnpdf li button:hover,
.at-btnpdf li button:focus {
  border: 0;
  color: #fff;
  outline: none;
  background: #02a2de;
}

.at-sessionId {
  font-size: 7.5rem;
  display: flex;
  justify-content: center;
  margin: 0;
}

.at-recordinghandler {
  left: 190px;
  color: #fff;
  z-index: 99;
  width: 200px;
  height: 76px;
  bottom: 26px;
  font-size: 22px;
  position: fixed;
  line-height: 12px;
  text-align: center;
  border-radius: 11%;
  border: 2px solid;
  background: #02a2de;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.at-recordinghandler i {
  cursor: pointer;
  font-size: 24px;
  padding: 12%;
}

.at-recordinghandler span {
  display: block;
  font-size: 12px;
}

.nsg-studentscreenholder {
}

.nsg-studentscreenholder span {
  font-size: 11px;
  width: 10px;
}

.nsg-studentscreenholder img {
  width: 16px;
  display: inline;
  margin-left: 9px;
  cursor: pointer;
}

.nsg-settings {
  padding: 0;
  list-style-type: none;
}

.nsg-settings li {
  width: 80%;
  padding: 0 0 20px 0;
}

.nsg-settings-icon {
  margin: 10px 0 10px 40px;
  display: inline-block;
  padding: 0;
  clear: none;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: rgb(44, 172, 227);
}

.nsg-settings-icon i {
  width: 100%;
  display: block;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
}

.nsg-settings-thickness {
  margin: 0 auto;
}

.nsg-fontsize-input {
  display: flex;
  margin: 0 auto;
}

.nsg-tools-heading {
  color: #fff;
  cursor: move;
  text-align: center;
}

.nsg-zoomreset {
  margin: 4px;
  cursor: pointer;
}

.nsg-selectall {
  font-size: 16px !important;
}

.at-btncloseslide {
  top: 5px;
  right: 5px;
  width: 20px;
  color: #fff;
  height: 20px;
  display: block;
  font-size: 13px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  background: #e04f5f;
}

.at-btncloseslide:hover,
.at-btncloseslide:focus {
  color: #fff;
}

.nsg-comparecastpopupcontainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1000;
  position: fixed;
  overflow: scroll;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.nsg-comparecastopenpopup {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nsg-votingPopup {
  width: 100%;
  float: left;
  opacity: 1;
  margin: -65px 0px -15px;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nsg-comparecast {
  width: 50%;
  float: left;
  height: 50%;
  position: relative;
  border: 1px solid #02a2de;
}

.nsg-comparecastclonsebtn {
  top: 80px;
  left: 15px;
  width: 36px;
  height: 36px;
  z-index: 15;
  font-size: 18px;
  cursor: pointer;
  margin: -70px 0 0;
  line-height: 36px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: #e04f5f;
  color: #fff !important;
}

.nsg-comparecastclonsebtnActivity {
  top: 30px;
  right: 15px;
  width: 36px;
  height: 36px;
  z-index: 15;
  font-size: 18px;
  cursor: pointer;
  margin: -70px 0 0;
  line-height: 36px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: #e04f5f;
  color: #fff !important;
}

.nsg-gamestoolsclonsebtn {
  top: 37px;
  right: 4px;
  width: 26px;
  height: 26px;
  z-index: 15;
  font-size: 14px;
  cursor: pointer;
  margin: -18px 0 0;
  line-height: 26px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: #e04f5f;
  color: #fff !important;
}

.nsg-studentname {
  top: 20px;
  margin: 0;
  z-index: 1;
  left: 20px;
  padding: 4px;
  color: #02a2de;
  background: #fff;
  position: absolute;
  border-radius: 20px;
  padding: 0 2px 0 8px;
}

.nsg-studentname a {
  width: 25px;
  height: 25px;
  margin: 4px;
  cursor: pointer;
  font-size: 18px;
  line-height: 25px;
  border-radius: 50%;
  background: #e04f5f;
  display: inline-block;
  vertical-align: middle;
}

.nsg-studentname a i {
  color: #fff;
}

.at-addtocompareholder label {
  margin: 0;
  width: 100%;
  float: left;
  border-radius: 2px;
  position: relative;
  padding: 0 0 0 24px;
  border: 2px solid #fff;
}

.at-dropdownvideo .at-screenimg {
  margin: 0;
  width: 100%;
  float: left;
  height: 120px;
  background: #000;
  position: relative;
  border-radius: 2px;
}

.mic-btn {
  cursor: pointer;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0 auto;
  border-radius: 50%;
  padding: 6px 0 0 0;
}

.mic-btn i {
  color: rgb(44, 172, 227);
}

.mic-mute {
}

.mic-unmute {
  background: red;
  /* border-radius: 50%; */
}

.nsg-clearallbtn {
  background: red;
  width: 22px;
}

.nsg-newmessagenotification {
  background: green url(images/chat-icon.png) no-repeat;
  background-size: contain;
  background-position: center center;
}

.nsg-slideremovebtn {
  margin: 0 10px 0 10px;
}

.nsg-usernameellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  margin: 13px 0 0 0;
}

.nsg-toolsholderpopupcontainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 15;
  position: fixed;
  overflow: scroll;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -ms-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.nsg-toolsholdertopenpopup {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nsg-size-checkbox li div label {
  font-size: 12px;
}

.at-toolsholder-nsg-small {
  width: 70px;
}

.at-toolsholder-nsg-small .at-dragable {
  height: 15px;
}

.at-toolsholder-nsg-small .at-tools li a {
  padding: 2px;
}

.at-toolsholder-nsg-small .at-tools li a img {
  width: 12px;
}

.at-toolsholder-nsg-small .at-btnfullscreen li a {
  padding: 2px;
}

.at-toolsholder-nsg-small .at-btnfullscreen li a img {
  width: 12px;
}

.at-toolsholder-nsg-small .at-btnfullscreen li a img {
  width: 12px;
}

.at-toolsholder-nsg-small .nsg-size-checkbox {
  line-height: 7px;
}

.at-toolsholder-nsg-md {
  width: 100px;
}

.at-toolsholder-nsg-md .at-dragable {
  height: 20px;
}

.at-toolsholder-nsg-md .at-tools li a {
  padding: 5px;
}

.at-toolsholder-nsg-md .at-tools li a img {
  width: 20px;
}

.at-toolsholder-nsg-md .at-btnfullscreen li a {
  padding: 5px;
}

.at-toolsholder-nsg-md .at-btnfullscreen li a img {
  width: 20px;
}

.at-toolsholder-nsg-md .at-btnfullscreen li a img {
  width: 20px;
}

.at-toolsholder-nsg-md .nsg-size-checkbox {
  line-height: 13px;
}

.nsg-question_option {
  background: #1c88bf;
  color: white;
  padding: 0.7rem;
  font-weight: 300;
  text-decoration: none;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0.1rem 1rem;
  border-bottom: 5px solid #156893;
  border-radius: 5px;
  cursor: pointer;
}

.nsg-votingHolder {
  right: 350px;
  color: #fff;
  z-index: 99;
  width: 70px;
  height: 70px;
  bottom: 30px;
  font-size: 22px;
  position: fixed;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid;
  background: #02a2de;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.nsg-bgWhite {
  background: #fff;
}

.nsg-bgGray {
  background: #6c757d;
}

.nsg-votingHolder i {
  font-size: 36px;
  padding: 23%;
}

.nsg-votingarea {
  padding: 0;
  z-index: 1;
  height: 300px;
}

.nsg-votingImageHolder {
  width: 100%;
  float: left;
  height: 100%;
  /* min-height: 500px; */
  max-height: 600px;
  /* overflow: scroll; */
  position: relative;
  border: 1px solid #02a2de;
}

.nsg-votingslideimg {
  height: 100%;
  width: 100%;
  max-height: 510px;
  min-height: 510px;
  overflow: auto;
}

.nsg-votingslideimg img {
  width: 100%;
  height: auto;
  /* max-width: 200px; */
}

.nsg-votingNext {
  right: 70px;
}

.nsg-votingPrevious {
  right: 120px;
}

.at-folderholder {
  width: 100%;
  float: left;
  height: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.at-folderholder ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}

.at-folderholder ul li {
  width: 203px;
  float: none;
  display: inline-block;
  list-style-type: none;
  white-space: nowrap;
}

.at-folderbtnviewname {
  width: 100%;
  float: left;
  background: #223d49;
  position: relative;
  border-radius: 0 0 3px 3px;
}

.at-folderbtnviewname span {
  float: left;
  color: #fff;
  width: 100%;
  opacity: 0.7;
  font-size: 11px;
  overflow: hidden;
  line-height: 31px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nsg-drivenType {
  color: white;
  font-size: 20px;
}

.nsg-optionbtnholder {
  right: 0;
  bottom: 0;
  position: absolute;
}

.nsg-question_option_answer {
  background: green !important;
}
