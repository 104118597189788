/*==============================================
			Sum Creater Style						
==============================================*/

.at-sumcalculatorholder {
  max-width: 430px;
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
}

.at-sumcreaterhead {
  width: 100%;
  float: left;
  position: relative;
  padding: 15px 25px;
  background: #02a2de;
}

.at-sumcreaterhead h2 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

.at-sumcreatercontent {
  width: 100%;
  float: left;
  background: #fff;
}
.at-sumarea {
  margin: 0;
  width: 100%;
  float: left;
  padding: 30px 0;
  list-style: none;
  text-align: center;
  -webkit-box-shadow: 0 5px 30px 0 rgba(23, 103, 141, 0.11);
  box-shadow: 0 5px 30px 0 rgba(23, 103, 141, 0.11);
}

/* .at-sumarea div {
    width: 50%;
    padding: 0 15px;
    list-style-type: none;
    display: inline-block;
    vertical-align: middle;
} */

.at-sumarea div > div {
  margin: 0;
  padding-top: 20px;
}
.at-sumarea div > div select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  width: 100%;
  height: 50px !important;
  font-size: 14px;
  font-family: Poppins, Serif;
  line-height: 28px;
  color: #262626;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.at-sumarea div > div select:focus {
  border-color: #ccc;
  -webkit-box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
}

.at-btnholder {
  width: 100%;
  float: left;
  padding: 20px 0 0;
}
