.at-fromsholer {
    width: 100%;
    float: left;
    background: #fff;
    min-height: 650px;
    padding: 30px 40px;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column
}

.at-fromsholer div, .at-fromsholer .register-head {
    width: 100%;
    float: left;
}

.at-fromsholer form {
    width: 100%;
    float: left;
}

.at-fromsholer form p {
    width: 100%;
    float: left;
    font-size: 14px;
    line-height: 14px;
    margin: 20px 0 0;
}

.zero-padding {
    padding: 0px;
    position: relative;
}

.background-image {
    left: 0;
    right: 0;
    opacity: 1;
    min-height: 700px;
    height: 100vh;
    background-image: url(./images/bg02.jpg);
    background-size: cover;
}

.container-outer {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column
}

.at-socialshare {
    width: 100%;
    float: left;
    text-align: center;
}

.container-box {
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
    /*overflow: hidden;*/
    padding: 50px 0;
}

.container-box::after {
    display: block;
    clear: both;
    content: '';
}

.container-inner {
    overflow: hidden;
    border-radius: 10px;
    /*box-shadow: 0 0 20px 5px rgba(197, 197, 197, 0.9);
    -webkit-box-shadow: 0 0 20px 5px rgba(197, 197, 197, 0.9);
    -moz-box-shadow: 0 0 20px 5px rgba(197, 197, 197, 0.9);*/
    -webkit-box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.2);
}

.logo-dimention {
    width: 298px;
    height: 184px;
    display: block;
    margin: 0 auto;
    animation: vertical-Float 6s ease-in-out infinite;
}

@keyframes vertical-Float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.left-aligned {
    min-height: 650px;
    overflow: hidden;
    background: url(./images/bg03.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    position: relative;
}

.the-boxes {
    overflow: hidden;
    text-align: center;
    padding: 40px 0;
}

.login-heading {
    overflow: hidden;
    padding: 0 0 30px;
    color: #fff;
    font-size: 28px;
    position: relative;
    margin: 0 0 50px;
}

.login-heading::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 2px;
    background: #00aeef;
    left: 50%;
    bottom: 0;
    margin: 0 0 0 -30px;
}

.login-btns {
    display: inline-block;
    vertical-align: top;
    border-radius: 100%;
    margin: 0 10px;
    color: #fff;
    border: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    overflow: hidden;
    font-size: 20px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.login-btns:hover {
    background: inherit;
}

.login-btns span {
    display: inline-block;
    margin: 0;
    width: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.login-btns img {
    display: inline-block;
    vertical-align: top;
    margin: 12px 0 0;
}

.login-btns:hover span {
    width: 125px;
    opacity: 1;
    margin: 0 0 0 10px;
    visibility: visible;
}

.fb-btn, .fb-btn:hover {
    background: #2c5eca;
    color: #fff;
}

.twitter-btn, .twitter-btn:hover {
    background: #2cb4e6;
    color: #fff;
}

.google-btn, .google-btn:hover {
    background: #f1351d;
    color: #fff;
}
.microsoft-btn, .microsoft-btn:hover{
    color: #fff;
    background: #4285f4;
}
.logo {
    top: 18px;
    right: 15px;
    z-index: 3;
    display: inline-block;
    vertical-align: middle;
    background: url(./images/logo-two.png) no-repeat;
    background-size: cover;
    width: 125px;
    height: 40px;
    margin: 0 10px;
    position: absolute;
}

.logo span {
    display: block;
    height: 100%;
    text-indent: -9999px;
}

@media (max-width:1600px) {
    .background-image {
        min-height: 800px;
    }
}

@media (max-width:900px) {
    .container-box {
        max-width: 800px;
    }
    .register-head h1 {
        font-size: 20px;
        line-height: 20px;
    }
}

@media (max-width:800px) {
    .background-image {
        height: auto;
    }
    .left-aligned {
        min-height: 460px;
    }
    .container-box {
        max-width: 568px;
    }
    .container-inner .at-row {
        display: block;
    }
    .zero-padding {
        width: 100%;
        float: left;
    }
    .the-boxes {
        padding: 20px 0 0;
    }
}

/*@media screen and (max-width: 919px) {
    .container-box {
        width: 100%;
        padding: 0;
    }
    .container-inner {
        border-radius: 0;
    }
    .logo-dimention {
        width: 170px;
        height: auto;
    }
    .left-aligned {
        padding: 30px 40px;
        height: auto;
        min-height: 642px;
        width: 100%;
    }
    .login-btns {
        margin: 0 10px 10px;
    }
    .container-box {
        margin: 15px 0;
        border-radius: 8px;
        overflow: hidden;
    }
}
@media screen and (max-width: 767px) {
    .right-align button {
        display: block;
        float: none;
        margin: 0 auto;
    }
}
@media screen and (max-width: 480px) {
    .left-aligned{min-height: auto;}
    .zero-padding{padding: 0 10px;}
}*/