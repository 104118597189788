.rcw-conversation-container > .rcw-header {
	background-color: #02a2de;
}

.rcw-conversation-container > .rcw-header > .rcw-title {
	color : #ffffff;
}

.rcw-widget-container > .rcw-launcher {
	background-color: #02a2de;
	color : #ffffff;
}

/*.rcw-message > .rcw-response {*/
/*	background-color: black;*/
/*	color: white;*/
/*}*/
