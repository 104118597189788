.at-stopwatchcontent {
	width: 100%;
	float: left;
	position: relative;
	background: #fff;
	height: calc(100% - 48px);
}

.at-calculatorholder {
	top: 10%;
	left: 40%;
	min-width: 300px;
	width: 525px;
	float: left;
	z-index: 11;
	display: none;
	background: #fff;
	border-radius: 3px;
	position: absolute;
	border: 1px solid #02a2de;
	-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.at-opancalculator {
	width: 100%;
	float: left;
	height: 100%;
	display: block;
	position: relative;
}