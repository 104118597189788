.form-group {
  position: relative;
}

.form-group label,
.form-group .form-control {
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}

.form-group.-animated {
  padding-top: 20px;
}

.form-group input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-family: Poppins, Serif;
  line-height: 28px;
  color: #262626;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.form-group input:focus {
  border-color: #ccc;
  -webkit-box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
}
.form-group label {
  margin: 0;
  top: 35px;
  left: 14px;
  z-index: 99;
  height: 20px;
  color: #a9a9a9;
  font-size: 12px;
  padding: 0 10px;
  background: #fff;
  line-height: 20px;
  text-align: center;
  position: absolute;
}
.form-group input:focus + label,
.form-group input + label:hover,
.form-group input:active + label,
.form-group input:focus-within + label,
.form-group input:hover + label,
.form-group input[type="”text”"][value]:not([value="””"]) {
  top: 8px;
}
.form-group input:focus {
  -webkit-box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
}
.form-group.-animated.-active label {
  top: 8px;
  background-color: #fff;
}

.error-field {
  position: absolute;
  right: 0;
  top: 64px;
  background: #fff;
  z-index: 999;
  padding: 0 14px;
  color: #ff0000;
  font-size: 11px;
  line-height: 10px;
}

input.error {
  border-color: #fdb8b8;
}

@media screen and (max-width: 919px) {
  .error-field {
    position: relative;
    right: auto;
    top: auto;
    background: #fff;
    z-index: 999;
    padding: 0 14px;
    color: #ff0000;
    font-size: 11px;
    line-height: 10px;
  }
}
