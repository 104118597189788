.at-drivebtnmain {
	left: 0;
	bottom: 0;
	padding: 0;
	width: 100%;
	height: 300px;
	position: fixed;
	background: #294957;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.at-drivebtnmainaddpadding {
	padding: 0 0 0 300px;
}

.at-drivebtnsholder {
	margin: 0;
	padding: 0;
	float: left;
	width: 100%;
	cursor: pointer;
	max-width: 260px;
	list-style: none;
	background: #153345;
	-webkit-box-shadow: 3px 0 20px 0 rgba(0, 0, 0, 0.30);
	box-shadow: 3px 0 20px 0 rgba(0, 0, 0, 0.30);
}

.at-drivebtnsholder li {
	width: 100%;
	float: left;
	padding: 0 0 0 7px;
	position: relative;
	list-style-type: none;
}

.at-drivebtnsholder li.at-dropox:before {
	background: #0b5083;
}

.at-drivebtnsholder li.at-gdrive:before {
	background: #d54938;
}

.at-drivebtnsholder li.at-onedrive:before {
	background: #0b5083;
}

.at-drivebtnsholder li.at-mycomputer:before {
	background: #0074d2;
}

.at-drivebtnsholder li.at-youtube:before {
	background: #c4171d;
}

.at-drivebtnsholder li:nth-child(even) {
	background: #123a55;
}

.at-drivebtnsholder li:before {
	top: 0;
	left: 0;
	content: '';
	width: 7px;
	height: 100%;
	position: absolute;
	background: #0b5083;
}

.at-drivebtnsholder li a {
	width: 100%;
	float: left;
	display: block;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	text-align: left;
	line-height: 14px;
	position: relative;
	padding: 0 16px 0 0;
}

.at-drivebtnsholder li a:before {
	top: 0;
	left: 0;
	content: '';
	width: 3px;
	height: 100%;
	position: absolute;
	background: #162a33;
}

.at-drivebtnsholder li a:after {
	top: 0;
	opacity: 0;
	right: 5px;
	color: #1087dd;
	font-size: 15px;
	content: '\f105';
	line-height: 50px;
	visibility: hidden;
	position: absolute;
	font-family: 'FontAwesome';
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-drivebtnsholder li:hover a:after,
.at-drivebtnsholder li.at-active a:after {
	opacity: 1;
	right: 15px;
	visibility: visible;
}

.at-drivebtnsholder li a img,
.at-drivebtnsholder li a span {
	display: inline-block;
	vertical-align: middle;
}

.at-drivebtnsholder li a span {
	color: #9e9e9e;
	line-height: 50px;
	padding: 0 0 0 18px;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-drivebtnsholder li:hover a span,
.at-drivebtnsholder li.at-active a span {
	color: #fff;
}

.at-btnviewfiles {
	top: 0;
	right: 0;
	opacity: 0;
	z-index: 9;
	width: 50px;
	height: 50px;
	color: #1087dd;
	cursor: pointer;
	font-size: 16px;
	line-height: 16px;
	visibility: hidden;
	position: absolute;
	line-height: 50px;
}

.at-drivebtnsholder li.at-showcatagory i {
	opacity: 1;
	visibility: visible;
}

.at-catagoryholder {
	max-width: 250px;
	width: 100%;
	min-height: 250px;
	float: left;
	padding: 15px 0 15px 15px;
	background: #172b34;
	border-radius: 1px solid #23404b;
	-webkit-box-shadow: 3px 0 20px 0 rgba(0, 0, 0, 0.30);
	box-shadow: 3px 0 20px 0 rgba(0, 0, 0, 0.30);
}

.at-catagorybtnholder {
	width: 100%;
	float: left;
	height: 220px;
	overflow-y: auto;
	text-align: left;
	overflow-x: hidden;
	border-radius: 0 6px 9px 0;
}

.at-catagroyconetntholder {
	width: 100%;
	float: left;
}

.at-catagroyconetntholder div {
	width: 100%;
	float: left;
	position: relative;
}

.at-catagroyconetntholder div button {
	top: 6px;
	border: 0;
	right: 40px;
	color: #fff;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	background: none;
	line-height: 16px;
	position: absolute;
	border-radius: 8px;
	background: #e05161;
}

.at-catagroyconetntholder div button:focus {
	border: 0;
	outline: none;
}

.at-catagroyconetntholder + .at-catagroyconetntholder {
	border-top: 1px solid #3a3838;
}

.at-catagroyconetntholder:first-child {
	padding-top: 0;
}

.at-btncatagoryfile {
	width: 100%;
	float: left;
	color: #acacac;
	display: block;
	font-size: 13px;
	line-height: 13px;
	position: relative;
	padding: 0 0 0 30px;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-btncatagoryfile img {
	left: 0;
	top: 50%;
	position: absolute;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.at-btncatagoryfile:hover,
.at-btncatagoryfile:focus {
	color: #fff;
}

.at-btncatagoryfile i,
.at-btncatagoryfile span {
	display: inline-block;
	vertical-align: middle;
}

.at-btncatagoryfile span {
	display: block;
	padding: 8px 0;
	position: relative;
	border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.at-btncatagoryfile i {
	margin: 0 8px 0 0;
}

.at-filecount {
	top: 6px;
	right: 10px;
	color: #fff;
	width: 24px;
	height: 16px;
	font-size: 12px;
	cursor: pointer;
	font-weight: 500;
	line-height: 16px;
	position: absolute;
	font-style: normal;
	text-align: center;
	border-radius: 7px;
	background: #e05161;
}

.at-catagroyconetntholder ul {
	margin: 0;
	padding: 0;
	width: 100%;
	float: left;
	list-style: none;
}

.at-catagroyconetntholder ul li {
	width: 100%;
	float: left;
	padding: 6px 0;
	list-style-type: none;
}

.at-catagroyconetntholder ul li + li {
	border-top: 1px solid #153344;
}

.at-lactureimg {
	width: 100%;
	float: left;
	margin: 0 0 20px;
	border-radius: 3px;
	border: 2px solid #fff;
}

.at-lactureimg img {
	width: 100%;
	height: auto;
	display: block;
	border-radius: 3px;
}

.at-filename {
	width: 100%;
	float: left;
}

.at-filename span {
	width: 100%;
	float: left;
	color: #fff;
	opacity: 0.30;
	display: block;
	font-size: 15px;
	padding: 0 0 10px;
	line-height: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.at-btnaction {
	float: left;
	color: #fff;
	opacity: 0.50;
	font-size: 20px;
	line-height: 20px;
}

.at-btnaction + .at-btnaction {
	float: right;
}

.at-btnaction:hover {
	color: #fff;
}

.at-editplaybtnholder {
	float: right;
	max-width: 265px;
	width: 100%;
	min-height: 300px;
	padding: 30px 20px;
	background: #172b34;
	-webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.30);
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.30);
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	justify-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	flex-direction: column;
}

.at-editplaybtnholder span {
	color: #fff;
	display: block;
	font-size: 13px;
	padding: 0 0 25px;
	line-height: 20px;
	text-align: center;
}

.at-buttonsholder {
	width: 100%;
	float: left;
}

.at-playeditbtn {
	padding: 10px;
	display: inline-block;
	vertical-align: middle;
}

.at-playeditbtn img {
	width: 100%;
	height: auto;
	display: block;
}

.at-slidesarea {
	padding: 25px 30px;
	overflow: hidden;
	min-height: 300px;
	text-align: left;
}

.at-slideheading {
	width: 100%;
	float: left;
	padding: 0 0 25px;
}

.at-slideheading h3 {
	margin: 0;
	color: #fff;
	opacity: 0.50;
	font-size: 14px;
	line-height: 17px;
}

.at-slidesholder {
	width: 100%;
	/* overflow-x: scroll; */
	float: left;
	position: relative;
}

.at-slidesholder ul {
	margin: 0;
	padding: 0;
	height: 200px;
	list-style: none;
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;
}

.at-slidesholder ul li {
	width: 250px;
	float: none;
	display: inline-block;
	vertical-align: middle;
	list-style-type: none;
	white-space: nowrap;
}

.at-slide {
	width: 20%;
	float: left;
	cursor: move;
	padding: 0 15px;
	list-style: none;
}

.at-slideimg {
	margin: 0;
	width: 100%;
	float: left;
	overflow: hidden;
	border-radius: 3px;
	position: relative;
	background: #fff;
	border: 4px solid #29a2de;
}

.at-slideimg img {
	width: auto;
	height: 159px;
	display: block;
	margin: 0 auto;
	max-width: none;
}

.at-btncloseslide {
	top: 5px;
	right: 5px;
	width: 20px;
	color: #fff;
	height: 20px;
	display: block;
	font-size: 13px;
	line-height: 20px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	background: #e04f5f;
}

.at-btncloseslide:hover,
.at-btncloseslide:focus {
	color: #fff;
}

.at-btnviewname {
	width: 100%;
	float: left;
	background: #223d49;
	position: relative;
	padding: 0 45px 0 14px;
	border-radius: 0 0 3px 3px;
}

.at-btnviewname span {
	float: left;
	color: #fff;
	width: 100%;
	opacity: 0.70;
	font-size: 11px;
	overflow: hidden;
	line-height: 31px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.at-btnview {
	top: 0;
	right: 0;
	width: 42px;
	height: 100%;
	text-align: center;
	position: absolute;
	background: rgba(0, 0, 0, 0.16);
	border-radius: 0 0 3px 3px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	justify-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	flex-direction: column;
}

.at-btnarea {
	width: 100%;
	float: left;
	padding: 50px 0 0;
	text-align: center;
}

.at-previewarea {
	width: 100%;
	float: left;
	height: 90%;
	text-align: center;
	position: relative;
}

.at-conetnpreview {
	width: 80%;
	height: 95%;
	margin: 0 auto;
	padding: 0 10px;
	overflow: hidden;
	position: relative;
	display: inline-block;
}

.nsg-fileDropArea {
	width: 20%;
	height: 100%;
	padding: 0 10px;
	float: left;
}

.at-conetnpreview .at-previewimg {
	margin: 0;
	width: 100%;
	float: left;
	height: 100%;
}

.at-conetnpreview .at-previewimg img {
	width: auto;
	height: 100%;
	display: block;
	margin: 0 auto;
}

.at-btnnextprev {
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	z-index: 2;
	list-style: none;
	position: absolute;
}

.at-btnnextprev li {
	float: left;
	padding: 0 6px;
	list-style-type: none;
}

.at-btnnextprev li:last-child {
	padding-right: 0;
}

.at-btnprev,
.at-btnnext {
	width: 40px;
	height: 40px;
	opacity: 0.80;
	display: block;
	font-size: 22px;
	line-height: 38px;
	text-align: center;
	background: #f2f2f2;
	border-radius: 10px;
	color: #cdcdcd !important;
	border: 1px solid #cdcdce;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-btnprev:hover,
.at-btnnext:hover {
	background: #02a2de;
	border-color: #02a2de;
	color: #fff !important;
}

.at-divebtnholder {
	width: 50px;
	height: 50px;
	float: left;
	line-height: 50px;
	text-align: center;
	position: relative;
}

.at-divebtnholder:before {
	top: 50%;
	right: 0;
	content: '';
	width: 2px;
	height: 26px;
	position: absolute;
	background: #0e4973;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@media (max-width: 1080px) {
	.at-drivebtnsholder {
		max-width: 210px;
	}
}

@media (max-width: 1024px) {
	.at-drivebtnsholder {
		max-width: 57px;
	}
	.at-responsivewidth {
		max-width: 110px;
	}
	.at-editplaybtnholder span {
		display: none;
	}
	.at-editplaybtnholder {
		max-width: 65px;
		padding: 30px 10px;
	}
	.at-drivebtnsholder li a span {
		display: none;
	}
	/*.at-editplaybtnholder*/
	.at-conetnpreview .at-previewimg img {
		width: 100%;
	}
	/*.at-conetnpreview,
  .at-previewarea{
    height: auto;
  }*/
	.at-drivebtnmain {
		bottom: 40px;
	}
	.at-slidesarea {
		padding: 9px 20px;
	}
}

@media (max-width: 900px) {
	/*.at-editplaybtnholder {
    max-width: 80px;
    padding: 30px 20px;
  }*/
	.at-playeditbtn {
		width: 40px;
		padding: 10px 0;
	}
}

@media (max-width: 767px) {
	.at-catagoryholder {
		max-width: 190px;
	}
}

/* maxifjaved video  controll css */

.at-videocontrols {
	margin: 0;
	padding: 0 5px;
	max-width: 600px;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	text-align: center;
	background: #fff;
	border-radius: 0 0 5px 5px;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
}

.at-videocontrols ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: inline-block;
	vertical-align: middle;
}

.at-videocontrols ul li {
	list-style-type: none;
	display: inline-block;
	vertical-align: middle;
}

.at-videocontrols ul li:nth-child(2),
.at-videocontrols ul li:nth-child(3) {
	float: left;
}

.at-videocontrols ul li:nth-child(4),
.at-videocontrols ul li:nth-child(5),
.at-videocontrols ul li:nth-child(6),
.at-videocontrols ul li:last-child {
	float: right;
}

.at-inputrange {
	width: 100%;
}

.at-videocontrols .at-inputrange li {
	width: 100%;
}

.hide {
	display: none;
}

.at-btnpdf {
	margin: 0;
	left: 50%;
	padding: 0;
	width: auto;
	bottom: 10px;
	list-style: none;
	position: absolute;
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.at-btnpdf li {
	float: left;
	list-style-type: none;
}

.at-btnpdf li + li {
	padding: 0 0 0 10px;
}

.at-btnpdf li button {
	border: 0;
	color: #999;
	display: block;
	cursor: pointer;
	font-size: 18px;
	padding: 0 20px;
	font-weight: 400;
	box-shadow: none;
	line-height: 38px;
	text-align: center;
	border-radius: 5px;
	background: #f2f2f2;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.at-btnpdf li button:hover,
.at-btnpdf li button:focus {
	border: 0;
	color: #fff;
	outline: none;
	background: #02a2de;
}

.at-slidesholder .at-addslidebtn {
	color: #fff;
	width: auto;
	opacity: 0.5;
	font-size: 55px;
	cursor: pointer;
	padding: 0 20px;
	line-height: 35px;
}
