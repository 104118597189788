/* By maxifjaved */

.right-align {
  width: 100%;
  float: left;
  background: #fff;
  position: relative;
}

.register-head h1 {
  margin: 0 0 3px;
  color: #333;
  font-size: 28px;
  line-height: 28px;
  text-transform: uppercase;
}

.register-head p {
  margin: 0;
  color: #8c8c8c;
}

.register-head {
  padding: 0;
  position: relative;
}

.register-head::after {
  display: block;
  clear: both;
  content: "";
}

/* .right-align button {
  padding-left: 25px;
  padding-right: 25px;
  height: 46px;
  width: 100%;
  border-radius: 10px;
  border-color: #83c81f;
  background: #83c81f;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  -webkit-box-shadow: 0 0 20px 0 rgba(131, 200, 31, 0.9);
  -moz-box-shadow: 0 0 20px 0 rgba(131, 200, 31, 0.9);
  box-shadow: 0 0 20px 0 rgba(131, 200, 31, 0.9);
} */

.btn-holo {
  display: inline-block;
  vertical-align: top;
  color: #1890ff;
  text-align: center;
}

.btn-holo:hover {
  text-decoration: underline;
}

/* .right-align button:hover {
  background: #78bc16;
} */

.right-footer {
  text-align: center;
  padding: 0;
  margin: 15px 0 0;
}

.right-footer:after {
  content: "";
  clear: both;
  display: block;
}
@media (max-width: 480px) {
  .register-head h1 {
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0 0;
  }
}
